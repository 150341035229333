<template>
  <div>
    <b-row class="align-items-center">
      <b-col cols="12" md="2" xxl="1">
        <div class="bg-light text-center py-50 rounded-lg">
          <img
            class="border rounded-lg"
            :src="product.data.primary_image"
            :alt="product.data.product_name"
            :height="80"
          />
        </div>
      </b-col>
      <b-col cols="12" md="10" xxl="11">
        <h2 class="h5 font-weight-bolder ml-3">
          {{ product.data.product_name }}
        </h2>
        <b-row class="align-items-center ml-2">
          <b-col cols="12" md="2">
            <b-badge variant="light">
              <span class="h4 d-block text-dark p-50 mb-0"
                >x {{ product.data.qty }}</span
              >
            </b-badge>
          </b-col>
          <b-col cols="12" md="4">
            <span class="h4 text-primary mb-0">
              {{ appUtils.numberFormat(product.data.price, "VNĐ") }}
              <span class="text-secondary"
                >/
                {{
                  product.data && product.data.product_variant_unit_name
                }}</span
              >
            </span>
          </b-col>
          <b-col cols="12" md="3">
            <div
              v-if="
                product.order_item_form_attachments &&
                product.order_item_form_attachments.length > 0
              "
              @click="handleShowModalCustomerFiles"
              class="attachment-product d-flex align-items-center justify-content-center"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 6H9.5C9.23478 6 8.98043 6.10536 8.79289 6.29289C8.60536 6.48043 8.5 6.73478 8.5 7V11C8.5 11.2652 8.60536 11.5196 8.79289 11.7071C8.98043 11.8946 9.23478 12 9.5 12H15.5C15.7652 12 16.0196 11.8946 16.2071 11.7071C16.3946 11.5196 16.5 11.2652 16.5 11V7C16.5 6.73478 16.3946 6.48043 16.2071 6.29289C16.0196 6.10536 15.7652 6 15.5 6ZM14.5 10H10.5V8H14.5V10ZM17.5 2H5.5C5.23478 2 4.98043 2.10536 4.79289 2.29289C4.60536 2.48043 4.5 2.73478 4.5 3V21C4.5 21.2652 4.60536 21.5196 4.79289 21.7071C4.98043 21.8946 5.23478 22 5.5 22H17.5C18.2956 22 19.0587 21.6839 19.6213 21.1213C20.1839 20.5587 20.5 19.7956 20.5 19V5C20.5 4.20435 20.1839 3.44129 19.6213 2.87868C19.0587 2.31607 18.2956 2 17.5 2ZM18.5 19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H6.5V4H17.5C17.7652 4 18.0196 4.10536 18.2071 4.29289C18.3946 4.48043 18.5 4.73478 18.5 5V19Z"
                  fill="#476DD6"
                />
              </svg>

              <div>{{ $t("lbl_customer_files") }}</div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="text-right ml-auto"
            v-if="product.data.status !== SUPPLIER_ORDERS_STATUS.CANCELED"
          >
            <v-select
              class="bg-white"
              v-model="selectedOption"
              :clearable="false"
              label="name"
              :options="options"
              @input="(value) => handleChangeSelected(value, product)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="text-right ml-auto"
            v-if="product.data.status !== SUPPLIER_ORDERS_STATUS.CANCELED"
          >
            <b-button variant="danger" block @click="rejectOrder">{{
              $t("lbl_reject_supplier_order")
            }}</b-button>
          </b-col>
          <b-col cols="12" md="3" class="text-right ml-auto" v-else>
            <div
              class="cancel-order d-flex align-items-center justify-content-center"
            >
              {{ $t("lbl_reject_supplier_order_status") }}
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="pt-2">
      <b-col>
        <Definition
          :ordersId="ordersId"
          :dataProp="product.order_item_attachments"
        />
      </b-col>
      <b-col>
        <b-button
          @click="handleCreateRequisitionForm(product)"
          variant="primary"
          >{{ $t("lbl_create_requisition_form") }}</b-button
        >
      </b-col>
    </b-row>

    <b-modal
      v-if="product && product.data && product.data.id"
      :id="`customer-files-modal-${product.data.id}`"
      size="lg"
      scrollable
      hide-header
      :ok-title="$t('lbl_update')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalCustomerFiles"
      :ok-disabled="checkOrderFormFile(product)"
      @ok="handleSubmitStatus(product.order_item_form_attachments)"
      @hide="handleRefreshData"
    >
      <CustomerFiles :dataProp="product.order_item_form_attachments" />
    </b-modal>

    <b-modal
      v-if="product && product.data && product.data.id"
      :id="`modal-requisition-form-${product.data.id}`"
      size="xl"
      scrollable
      hide-header
      :ok-title="$t('lbl_create')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalRequisitionForm"
      @ok="handleTriggerRequisitionForm"
      @hide="closeModalRequisitionForm"
    >
      <CreateRequisitionForm
        @onCreate="handleSubmitRequisitionForm"
        :createRequisitionFormCount="createRequisitionFormCount"
        :productData="product"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import vSelect from "vue-select";
import appUtils from "@/utils/appUtils";
import { ORDERS_STATUS, SUPPLIER_ORDERS_STATUS } from "@/utils/constant";
import { createPopper } from "@popperjs/core";
import { ref } from "@vue/composition-api";
import useOrdersList from "../../useOrdersList";
import Definition from "./Definition.vue";
import CustomerFiles from "./CustomerFiles.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CreateRequisitionForm from "../../../PrintOrderForm/CreateRequisitionForm.vue";

export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
    ordersId: Number,
  },
  components: {
    vSelect,
    Definition,
    BModal,
    CustomerFiles,
    CreateRequisitionForm,
  },
  data() {
    return {
      appUtils,
      options: [],
      selected: {},
      selectedOption: null,
      placement: "bottom",
      showModalCustomerFiles: false,
      SUPPLIER_ORDERS_STATUS,
      showModalRequisitionForm: false,
      createRequisitionFormCount: 0,
    };
  },
  beforeMount() {
    let data = [];
    Object.entries(ORDERS_STATUS).map(([key, value]) => {
      if (
        value === ORDERS_STATUS.WAITING_CONFIRM ||
        value === ORDERS_STATUS.ERROR_PAYMENT
      ) {
        return;
      }

      data.push({
        label: this.$t(key),
        value,
      });
    });

    this.options = [...data];
    this.options = [...this.product.deliverable_status];
    this.selectedOption = this.product.currentStatus;
  },
  watch: {
    product() {
      this.selectedOption = this.product.currentStatus;
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
    handleSelecting(option) {
      if (option.value === ORDERS_STATUS.CANCELED) {
        const nodeEl = this.$createElement;
        const messageVNode = nodeEl("div", {
          domProps: {
            innerHTML: `Are you sure want to update <strong>${option.label}</strong>`,
          },
        });

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: "Please confirm and enter reason",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.updateStatusOrder(this.ordersId, {
                id: record.data.id,
                order_number: option.order_number,
                max_steps: option.max_steps,
              });
              // Code here...
              console.log(value);
            }
          })
          .catch((err) => {
            // An error occurred
            console.log(err);
          });
        return;
      }
    },
    handleChangeSelected(option, record) {
      if (!record?.data?.id) return;
      console.log(record);
      console.log(record.deliverable_status);

      const lastIndexOrderItem = record.deliverable_status.length;
      const foundUrlEmpty = record.order_item_form_attachments.find(
        (x) => x.url === ""
      );
      console.log(foundUrlEmpty);
      if (
        record.deliverable_status.length &&
        option.order_number ===
          record.deliverable_status[lastIndexOrderItem - 1].order_number &&
        foundUrlEmpty
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_this_test_order_requires_documents"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
      this.updateStatusOrder(this.ordersId, {
        id: record.data.id,
        order_number: option.order_number,
        max_steps: option.max_steps,
      });
    },
    rejectOrder() {
      const nodeEl = this.$createElement;
      const messageVNode = nodeEl("div", {
        domProps: {
          innerHTML: this.$t("lbl_reject_supplier_order_content"),
        },
      });

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t("lbl_please_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("lbl_yes"),
          cancelTitle: this.$t("lbl_no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.updateStatusOrder(this.ordersId, {
              id: this.product.data.id,
              order_number: SUPPLIER_ORDERS_STATUS.CANCELED,
              max_steps: this.options[0].max_steps,
            });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
      return;
    },
    handleShowModalCustomerFiles() {
      this.showModalCustomerFiles = true;
    },
    async handleSubmitStatus(data) {
      if (!data?.length) {
        this.showModalCustomerFiles = false;
        return;
      }

      try {
        await Promise.all(
          data.map(async (item) => {
            if (!item?.status?.id) return;
            // if (item.status.id === 3 && !item.description) {
            //   this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: this.$t("lbl_description_field_cannot_be_empty"),
            //       icon: "AlertTriangleIcon",
            //       variant: "danger"
            //     }
            //   });
            //   return;
            // }
            const params = {
              status: item.status.id,
            };

            if (item.status.id === 3) {
              params.reason = item.reason;
            }

            await this.$rf
              .getRequest("CommonRequest")
              .putOrderFormItem(item.id, params);
          })
        );
        this.showModalCustomerFiles = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_update_status_success"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.viewDetailOrders({ id: this.ordersId });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_update_status_fail"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    handleRefreshData() {
      this.viewDetailOrders({ id: this.ordersId });
      this.showModalCustomerFiles = false;
    },
    checkOrderFormFile(product) {
      return product?.order_item_form_attachments?.every((item) => !item?.url);
    },
    handleCreateRequisitionForm(product) {
      const orderItemId = product?.data?.id;
      if (!orderItemId) return;
      this.showModalRequisitionForm = true;
    },
    closeModalRequisitionForm() {
      this.showModalRequisitionForm = false;
      // this.createRequisitionFormCount = 0;
    },
    handleTriggerRequisitionForm() {
      this.createRequisitionFormCount++;
    },
    handleSubmitRequisitionForm(formData) {
      if (!this.product?.data?.id) return;

      if (this.createRequisitionFormCount) {
        const data = {
          ...formData,
          orderId: this.product.data.id,
        };
        this.$store.commit("orders/updateRequisitionForm", data);
        this.createRequisitionFormCount = 0;
        this.$router.replace({
          name: "print-order-form",
          params: {
            id: this.product.data.id,
          },
        });
      }
    },
  },
  setup() {
    const dataTag = ref({});
    const { updateStatusOrder, viewDetailOrders } = useOrdersList();
    return {
      dataTag,
      updateStatusOrder,
      viewDetailOrders,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5) !important;
  transform: scale(0.7) !important;
}
.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(0.7) !important;
}
.vs__deselect svg {
  fill: currentColor !important;
}

.attachment-product {
  background-color: #dee4f8;
  color: #20419b;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cancel-order {
  background-color: #f8dede;
  color: #ff0000;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
