<template>
  <div class="pt-1">
    <template
      v-if="data.status === ORDERS_STATUS.CANCELED || data.status === ORDERS_STATUS.ERROR_PAYMENT"
    >
      <div class="d-flex justify-content-between align-items-center" style="min-height: 80px;">
        <span class="h4 mb-0">{{ $t(Object.keys(ORDERS_STATUS)[data.status-1]) }}</span>
        <span>
          <span
            class="d-flex text-lg bg-danger text-white border rounded-circle"
            style="width: 24px; height: 24px;"
          >
            <feather-icon class="m-auto" icon="XIcon" />
          </span>
        </span>
      </div>
    </template>
    <template v-else-if="data.status === ORDERS_STATUS.WAITING_CONFIRM">
      <div class="d-flex justify-content-between align-items-center mb-1" style="min-height: 80px;">
        <span class="h4 mb-0">{{ $t(Object.keys(ORDERS_STATUS)[data.status]) }}</span>
        <span>
          <span
            class="d-flex text-lg bg-light border rounded-circle"
            style="width: 24px; height: 24px;"
          >
            <feather-icon class="m-auto" icon="MoreHorizontalIcon" />
          </span>
        </span>
      </div>
    </template>
    <template v-else v-for="([key, value], index) of Object.entries(ORDERS_STATUS)">
      <div
        v-if="value !== ORDERS_STATUS.CANCELED && value !== ORDERS_STATUS.WAITING_CONFIRM && value !== ORDERS_STATUS.ERROR_PAYMENT"
        class="d-flex justify-content-between align-items-center mb-1"
        :key="index"
      >
        <span class="h4 mb-0">{{ $t(key) }}</span>
        <span :set="status = getOrderStatus(value === data.status ? data.status : 0)">
          <span
            :class="data.status > value ? 'bg-success' : status.classes"
            class="d-flex mb-0 text-white border rounded-circle"
            style="width: 24px; height: 24px;"
          >
            <feather-icon class="m-auto" :icon="value < data.status ?  'CheckIcon' : status.icon" />
          </span>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { ORDERS_STATUS } from "@/utils/constant";
export default {
  name: "OrdersStatus",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ORDERS_STATUS
    };
  },
  methods: {
    getOrderStatus(status) {
      switch (status) {
        case ORDERS_STATUS.RECEIVE_ORDERS:
          return {
            classes: "bg-primary text-white",
            icon: "LoaderIcon"
          };
        case ORDERS_STATUS.PROCESSING:
          return {
            classes: "bg-primary text-white",
            icon: "LoaderIcon"
          };
        case ORDERS_STATUS.SENDING:
          return {
            classes: "bg-primary text-white",
            icon: "LoaderIcon"
          };
        case ORDERS_STATUS.FINISHED:
          return {
            classes: "bg-success text-white",
            icon: "CheckIcon"
          };

        default:
          return {
            classes: "bg-white",
            icon: ""
          };
      }
    }
  }
};
</script>

<style>
</style>
